/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useState } from "react";
import Link from "next/link";
import { collection_data } from "../../data/collection_data";
import HeadLine from "../headLine";
import Image from "next/image";
import { useExplorerCollectionsQuery, useTopCollectionsQuery } from "../../redux/actions/collection";

import { BASEURL_IMG } from "../../utils/constant";
import CustomImage from "../global/CustomImage";
import Error from "../errors/Error";
import Loading from "../loaders/Loading";
import { ERROR } from "../../utils/errors";
import useTranslation from 'next-translate/useTranslation'

const Top_collection = () => {
  // const {data, isFetching, isError, error} = useExplorerCollectionsQuery();
  
  const { t, lang } = useTranslation('common') 
  const [timeActiveText, setTimeActiveText] = useState(t('last_30d'));
  const [days, setDays] = useState(30);
  const {data, isFetching, isError} = useTopCollectionsQuery({days})
  useEffect(() => {
   if(isError) {
    ERROR('Something went wrong')
   }
  }, [isError])

  useEffect(() => {
    setTimeActiveText(t('last_30d'))
  }, [lang])
  
  const [dropdownShow, setDropdownShow] = useState(false);
  const timeText = [
    {
      id: 1,
      text: t('last_24h'),
      days: 1
    },
    {
      id: 2,
      text: t('last_7d'),
      days: 7
    },
    {
      id: 3,
      text: t('last_30d'),
      days: 30
    },
  ];

  const handleFilter = ({text, days}) => {
    setTimeActiveText(text);
    setDays(days)
    const newCollectionData = collection_data.filter((item) => {
      if (text === "Last 30 days") {
        return item;
      }
      return item.postDate === text;
    });
    // setData(newCollectionData);
  };

  const handleDropdown = (e) => {
    window.addEventListener("click", (w) => {
      if (w.target.closest(".dropdown-toggle")) {
        if (dropdownShow) {
          setDropdownShow(false);
        } else {
          setDropdownShow(true);
        }
      } else {
        setDropdownShow(false);
      }
    });
  };

  return (
    <div>
      <section className=" relative py-24">
      
        
        <div className="container">
          
          <div className="font-display text-jacarta-700 mb-12 text-center text-lg sm:text-3xl dark:text-white flex justify-center items-center gap-x-3">
            <HeadLine text={t('hot_collections')} classes="inline" />

            <div className="dropdown cursor-pointer relative">
              
              <button
                className="dropdown-toggle text-accent border-jacarta-100 dark:text-white inline-flex items-center border px-3 py-2 dark:border-accent-dark rounded-xl"
                type="button"
                onClick={(e) => handleDropdown(e)}
              >
                {timeActiveText}
                
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-accent dark:fill-white h-7 w-7 ml-2"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
                </svg>
              </button>

              <div
                className={
                  dropdownShow
                    ? "dropdown-menu dark:bg-black border border-jacarta-100 dark:border-accent-dark z-10 mt-2 min-w-[200px] whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl show text-jacarta-700 dark:text-white absolute m-0 top-full"
                    : "dropdown-menu dark:bg-jacarta-900 z-10  min-w-[200px] whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl hidden text-jacarta-700 dark:text-white absolute m-0 top-full"
                }
              >
                {timeText.map(({ id, text,days }) => {
                  return (
                    <button
                      key={id}
                      onClick={() => {
                        handleFilter({text,days});
                      }}
                      className="block dropdown-text w-full text-left"
                    >
                      <span className="dropdown-item font-normal text-base dark:hover:bg-jacarta-600 hover:bg-jacarta-50 block dark:text-white rounded-xl px-5 py-2 transition-colors">
                        {text}
                      </span>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-[1.875rem] lg:grid-cols-4">
            {isFetching ? <Loading width={60} /> : data?.status && data?.data?.length === 0 ? <Error msg="There is no top collection record" /> : data?.data?.map((item) => {
              const { id, logo, collectionName, icon, amount, postTime ,totalItem} = item;
              console.log(item)
             
              return (
                <div
                  className="border-jacarta-100 dark:bg-black dark:border-accent-dark rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg"
                  key={id}
                >
                  <figure className="mr-4 shrink-0">
                    <Link
                      href={"/collection/" + id}
                      className="relative block w-[48px] h-[48px]"
                    >
                     
                      <CustomImage src={`${BASEURL_IMG}${logo}`} fallback={'/images/logo.png'} loaderWidth="30" />
                      {/* <div className="dark:border-jacarta-600 bg-jacarta-700 absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white text-xs text-white">
                        {id} 
                      </div> */}
               
                    </Link>
                  </figure>
                  <div>
                    <Link href={"/collection/" + id} className="block">
                      <span className="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
                        {collectionName}
                      </span>
                    </Link>
                    <div className="flex flex-col space-y-0">
                    <span className="dark:text-jacarta-300 text-sm">
                      {item?.marketCap} MATIC
                    </span>
                    <span className="dark:text-jacarta-300 text-sm">
                    <span>Items</span> {totalItem} 
                    </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mt-10 text-center">
           
          </div>
        </div>
      </section>
    </div>
  );
};

export default Top_collection;
