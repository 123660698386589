import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import { useSelector } from "react-redux";
import useTranslation from 'next-translate/useTranslation'

const hero = () => {
  const { t, lang } = useTranslation() 
  const [state] = useState(
    "The first native polygon art marketplace, collect, share and buy artwork from around the world"
  );
  const loginDetails = useSelector((state) => state.counter.loginDetails);
  return (
    <section className="relative pb-10 pt-20 md:pt-32 h-1527 ">
      <div className="container h-full mx-auto">
        <div className="grid h-full items-center gap-4 md:grid-cols-12">
          <div className="col-span-6 flex h-full flex-col items-center justify-center py-10 md:items-start md:py-20 xl:col-span-4">
            <h1 className="text-jacarta-700 font-bold font-display mb-6 text-center text-4xl dark:text-white md:text-left lg:text-6xl">
              {t('home:title')}
            </h1>
            <p className="dark:text-jacarta-200 mb-8 text-center text-lg md:text-left">
              {t('home:description')}
            </p>
            <div className="flex space-y-4 sm:space-y-0 sm:space-x-4 flex-col sm:flex-row">
              {loginDetails?.profile?.id && (
                <Link
                  href="/collection/create"
                  className="text-white bg-black dark:text-black dark:bg-white w-36 rounded-full py-3 px-8 text-center font-semibold transition-all hover:bg-accent-dark hover:text-white hover:dark:bg-accent-dark hover:dark:text-white"
                >
                  {t('common:create')}
                </Link>
              )}
              <Link
                href="/collection/explore_collection"
                className="text-white bg-black dark:text-black dark:bg-white w-36 rounded-full py-3 px-8 text-center font-semibold transition-all hover:bg-accent-dark hover:text-white hover:dark:bg-accent-dark hover:dark:text-white"
              >
                  {t('common:explore')}
              </Link>
            </div>
          </div>

          {/* <!-- Hero image --> */}
          <div className="col-span-6 xl:col-span-8">
            <div className="relative text-center md:pl-8 md:text-right">
              <Image
                width={560}
                height={560}
                src="/header.png"
                alt="hero"
                className="hero-img mt-8 inline-block w-72 rotate-[8deg] sm:w-full lg:w-[24rem] xl:w-[35rem]"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default hero;
