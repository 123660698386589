import React, { useEffect, useState } from "react";
// import { tranding_category_filter } from "../../data/categories_data";
import CategoryItem from "./categoryItem";
import { trendingCategoryData } from "../../data/categories_data";
import Tippy from "@tippyjs/react";
import Recently_added_dropdown from "../dropdown/recently_added_dropdown";
import { useSelector, useDispatch } from "react-redux";
import { updateTrendingCategoryItemData } from "../../redux/counterSlice";
import NFTCard from "../cards/NFTCard"
import { useTrandingNFTQuery } from "../../redux/actions/nft";
import { ERROR } from "../../utils/errors";
import { Spin } from "antd";
import { useCategoryListQuery } from "../../redux/actions/collection";
import NFTCardSlider from "../cards/NFTCardSlider";
import useTranslation from "next-translate/useTranslation";

const Trending_categories_items = () => {
  const {t, lang} = useTranslation('common')
  const [itemdata, setItemdata] = useState(trendingCategoryData);
  const dispatch = useDispatch();
  const { trendingCategorySorText } = useSelector((state) => state.counter);
  const [filterVal, setFilterVal] = useState(0);
  const useCategoryList = useCategoryListQuery()
  const [tranding_category_filter, setTranding_category_filter] = useState(useCategoryList?.data?.data || []);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');

  useEffect(() => {
    if (useCategoryList?.data?.data) {
      const translatedCategories = useCategoryList.data.data.map((obj) => {
        return {
          ...obj,
          category: t(obj.svg),
        };
      });

      setTranding_category_filter(translatedCategories);
    }
  }, [lang, useCategoryList.data]);

  const handleFilter = (category) => {
    setSelectedCategoryId(category)
  };

  const sortText = [
    {
      id: 1,
      text: "Recently Added",
    },
    {
      id: 2,
      text: "Price: Low to High",
    },
    {
      id: 3,
      text: "Price: high to low",
    },
    // {
    //   id: 4,
    //   text: "Auction Ending Soon",
    // },
  ];
  const loginDetails = useSelector((state) => state.counter.loginDetails);
  const { data, isFetching, isError } = useTrandingNFTQuery({
    user_id: loginDetails?.profile?.id || '',
    categoryId: selectedCategoryId
  })
  useEffect(() => {
    if (isError) {
      ERROR('Something went wrong')
    }
  }, [isError])
  // useEffect(() => {
  //   dispatch(updateTrendingCategoryItemData(itemdata.slice(0, 8)));
  // }, [itemdata, dispatch]);

  return (
    <Spin spinning={isFetching} tip="Loading...">
      {/* <!-- Filter --> */}
      <div className="mb-8 flex flex-wrap items-center justify-between">
        <ul className="flex flex-wrap items-center">

          <li className="my-1 mr-2.5" key={0}>
            <button
              className={
                filterVal === 0
                  ? "group bg-accent border-jacarta-100 font-display flex h-9 items-center rounded-lg border-2 px-4 text-sm font-semibold transition-colors border-transparent text-white dark:border-accent-dark capitalize"
                  : "dark:bg-black dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border-2 bg-white px-4 text-sm font-semibold transition-colors hover:dark:border-accent-dark hover:border-black hover:dark:border-2 dark:border-accent-dark/50 hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white capitalize"
              }
              onClick={() => {
                handleFilter('');
                setFilterVal(0);
              }}
            >
              {t('all')}
            </button>
          </li>

          {tranding_category_filter.map(({ id, svg, category }, index) => {

            return (
              <li className="my-1 mr-2.5" key={id}>
                <button
                  onClick={() => {
                    handleFilter(id);
                    setFilterVal(id);
                  }}
                >
                  <div
                    className={
                      filterVal === id
                  ? "group bg-accent border-jacarta-100 font-display flex h-9 items-center rounded-lg border-2 px-4 text-sm font-semibold transition-colors border-transparent text-white dark:border-accent-dark capitalize"
                  : "dark:bg-black dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border-2 bg-white px-4 text-sm font-semibold transition-colors hover:dark:border-accent-dark hover:border-black hover:dark:border-2 dark:border-accent-dark/50 hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white capitalize"
              
                    }
                  >
                    <svg
                      className={
                        filterVal === id
                          ? "icon mr-1 h-4 w-4 transition-colors fill-white"
                          : "icon fill-jacarta-700 dark:fill-jacarta-100 mr-1 h-4 w-4 transition-colors group-hover:fill-white"
                      }
                    >
                      <use xlinkHref={`/icons.svg#icon-${svg}`}></use>
                    </svg>
                    <span className="pt-1">{category}</span>
                  </div>
                </button>
              </li>
            );

          })}
        </ul>
        {/* dropdown */}
        {/* <Recently_added_dropdown data={sortText} dropdownFor="recently_added" /> */}
      </div>

      {/* <!-- Grid --> */}
      {/* <CategoryItem /> */}
      {/* <NFTCard data={data} edit={true} type="created" /> */}

      <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">
        {data?.data?.length !== 0 && data?.data?.map((item) => {
          return (
            <NFTCardSlider item={item} />
          );
        })}
      </div>

      {/* <NFTCardSlider item={data} /> */}
    </Spin>
  );
};

export default Trending_categories_items;
