import Link from "next/link";
import React, { useEffect } from "react";
import { items_Properties_data } from "../../data/items_tabs_data";
import Error from "../errors/Error";

const Properties = ({ properties: data }) => {
  return (
    <>
      {/* <!-- Properties --> */}
      <div
        className="tab-pane fade"
        id="properties"
        role="tabpanel"
        aria-labelledby="properties-tab"
      >
        <div className="dark:bg-black dark:border-accent-dark/50 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none rounded-tr-none border bg-white p-6 md:p-10">
          <div className="grid grid-cols-2 gap-5 sm:grid-cols-3 md:grid-cols-4">
            {data?.length === 0 ? (
              <Error msg={"There is no properties data"}></Error>
            ) : (
              data?.map((item, key) => {
                return (
                  <Link
                    href="#"
                    key={key}
                    className="dark:bg-jacarta-900 dark:border-jacarta-800 bg-light-base rounded-2lg border-jacarta-100 flex flex-col space-y-2 border p-5 text-center transition-shadow hover:shadow-lg"
                  >
                    <span className="text-accent text-sm uppercase dark:text-white">
                      {item?.type}
                    </span>
                    <span className="text-jacarta-700 text-base dark:text-white break-all">
                      {item?.name}
                    </span>
                    {/* <span className="text-jacarta-400 text-sm">{trait}</span> */}
                  </Link>
                );
              })
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Properties;
