import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Image from "next/image";
import "tippy.js/dist/tippy.css";
import { bidsData } from "../../data/bids_data";
import Link from "next/link";
import Tippy from "@tippyjs/react";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { bidsModalShow } from "../../redux/counterSlice";
import { useDispatch, useSelector } from "react-redux";
import Likes from "../likes";
import { useHotNFTListQuery } from "../../redux/actions/nft";
import { ERROR } from "../../utils/errors";
import { Spin } from "antd";
import Error from "../errors/Error";
import { BASEURL_IMG } from "../../utils/constant";
import { useEffect } from "react";
import CustomImage from "../global/CustomImage";
import TopNFTSCard from "../cards/TopNFTSCard";
import NFTCardSlider from "../cards/NFTCardSlider";

const BidsCarousel = () => {
  const dispatch = useDispatch();
  const loginDetails = useSelector((state) => state.counter.loginDetails);

  const { data, isFetching, isError } = useHotNFTListQuery(loginDetails?.profile?.id || '')
  useEffect(() => {
    if (isError) {
      ERROR('Something went wrong')
    }
  }, [isError])
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar]}
        spaceBetween={30}
        slidesPerView="auto"
        loop={true}
        breakpoints={{
          240: {
            slidesPerView: 1,
          },
          565: {
            slidesPerView: 2,
          },
          1000: {
            slidesPerView: 3,
          },
          1100: {
            slidesPerView: 4,
          },
        }}
        navigation={{
          nextEl: ".bids-swiper-button-next",
          prevEl: ".bids-swiper-button-prev",
        }}
        className=" card-slider-4-columns !py-5"
      >
        {data?.data?.length !== 0 && data?.data?.map((item) => {
          const { id, localFile, nftName, bid_number, eth_number, totalLike } =
            item;
          return (
            <SwiperSlide className="text-white" key={id}>
              <NFTCardSlider item={item} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* <!-- Slider Navigation --> */}
      <div className="group bids-swiper-button-prev swiper-button-prev shadow-white-volume absolute !top-1/2 !-left-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-left-6 after:hidden">
        <MdKeyboardArrowLeft />
      </div>
      <div className="group bids-swiper-button-next swiper-button-next shadow-white-volume absolute !top-1/2 !-right-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-right-6 after:hidden">
        <MdKeyboardArrowRight />
      </div>
    </>
  );
};

export default BidsCarousel;
