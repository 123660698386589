import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import Tippy from "@tippyjs/react";
import { BsPencil } from "react-icons/bs"
import "tippy.js/dist/tippy.css";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import Error from "../errors/Error";
import Likes from "../likes";
import { BASEURL_IMG } from "../../utils/constant";
import MintModal from "../modal/MintModal";
import CustomImage from "../global/CustomImage";
import { CONNECT_WALLET } from "../metamask/connector";
import PutOnSaleModal from "../modal/PutOnSaleModal";
import ViewBidsModal from "../modal/ViewBidsModal";

const TopNFTSCard = ({ item, edit = false, type }) => {
    const {
        id,
        localFile,
        nft_id,
        name,
        quantity,
        royalty,
        createdBy
      } = item;
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false)
  const updateModal = () => {
    setModal(!modal)
  }
  const loginDetails = useSelector((state) => state.counter.loginDetails);
  useEffect(() => {
    const wallet = async () => {
      const res = await CONNECT_WALLET();
      console.log(res)
    }
    wallet();
  }, [])
  return (
    <>
 
      <article key={id}>
              <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2.5xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
                <figure className="relative">
                  <Link href={`/item/${id}`} className="relative w-full h-[230px] block">
                    <CustomImage src={`${BASEURL_IMG}${localFile}`} fallback={'/images/logo.png'} loaderWidth="60" card={true} />
                  </Link>

                  <Likes like={!item?.totalLike ? 0 : item?.totalLike} userId={loginDetails?.profile?.id} nftId={id} />

                  <div className="absolute left-3 -bottom-3">
                    <div className="flex -space-x-2">
                      <Link href={`/item/${nft_id}`}>
                        <Tippy content={<span>creator: {'alex'}</span>}>
                          <Image
                            width={21}
                            height={21}
                            src={'/images/avatars/user.jpeg'}
                            alt="creator"
                            className="dark:border-jacarta-600 hover:border-accent dark:hover:border-accent h-6 w-6 rounded-full border-2 border-white"
                          />
                        </Tippy>
                      </Link>
                      <Link href={`/user/${createdBy}`}>
                        <Tippy content={<span>creator: {'alex'}</span>}>
                          <Image
                            width={21}
                            height={21}
                            src={'/images/avatars/user.jpeg'}
                            alt="owner"
                            className="dark:border-jacarta-600 hover:border-accent dark:hover:border-accent h-6 w-6 rounded-full border-2 border-white"
                          />
                        </Tippy>
                      </Link>
                    </div>
                  </div>
                </figure>
                <div className="mt-7 flex items-center justify-between">
                  <Link href={`/item/${nft_id}`}>
                    <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                      {name}
                    </span>
                  </Link>
                
                </div>
                <div className="mt-2 text-sm flex justify-between items-center gap-3">
                 
                </div>
              </div>
            </article>
    </>
  );
};

export default TopNFTSCard;
