import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import OfferTab from "./OfferTab";
import Properties from "./Properties";
import Activity_tab from "./Activity_tab";
import Price_history from "./Price_history";
import {config} from "../metamask/config"
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";

const ItemsTabs = ({properties, activity}) => {
  const {t, lang} = useTranslation('nft')
  const [tabsActive, setTabsActive] = useState(1);
  const router = useRouter();
  const pid = router.query.item;
  const tabsHeadText = [
    {
      id: 1,
      text: t('activities'),
      icon: "activity",
    },
    {
      id: 2,
      text: t('details'),
      icon: "details",
    },
    {
      id: 3,
      text: t('price_history'),
      icon: "price",
    },
    {
      id: 4,
      text: t('properties'),
      icon: "properties",
    },
  ];
  return (
    <>
      <div className="scrollbar-custom overflow-x-auto rounded-lg">
        {/* <!-- Tabs Nav --> */}
        <Tabs className="min-w-fit tabs">
          <TabList className="nav nav-tabs flex items-center">
            {/* <!-- Offers --> */}
            {tabsHeadText.map(({ id, text, icon }) => {
              return (
                <Tab className="nav-item bg-transparent" key={id}>
                  <button
                    className={
                      tabsActive === id
                        ? "nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white active"
                        : "nav-link hover:text-jacarta-700 dark:text-jacarta-400 hover:dark:text-accent-dark text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white"
                    }
                    onClick={() => setTabsActive(id)}
                  >
                    <svg className="icon mr-1 h-5 w-5 fill-current">
                      <use xlinkHref={`/icons.svg#icon-${icon}`}></use>
                    </svg>
                    <span className="font-display text-base font-medium">
                      {text}
                    </span>
                  </button>
                </Tab>
              );
            })}
          </TabList>
          <TabPanel>
            
            <Activity_tab activity={activity} />
          </TabPanel>
          <TabPanel>
            {/* <!-- Details --> */}
            <div
              className="tab-pane fade"
              id="details"
              role="tabpanel"
              aria-labelledby="details-tab"
            >
              <div className="dark:bg-black dark:border-accent-dark/50 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none border rounded-tr-none bg-white p-6 md:p-10">
                <div className="mb-2 flex items-center">
                  <span className="dark:text-jacarta-300 mr-2 min-w-[9rem] font-bold">
                    Contract Address:
                  </span>
                  <a target="_blank" href={`${config?.BASEURL_BLOCKCHAIN}/address/${config?.contractAddress}`} className="text-accent dark:text-white">
                    {config?.contractAddress}
                  </a>
                </div>
                <div className="mb-2 flex items-center">
                  <span className="dark:text-jacarta-300 mr-2 min-w-[9rem] font-bold">
                    Token ID:
                  </span>
                  <span
                    className="js-copy-clipboard text-jacarta-700 cursor-pointer select-none dark:text-white"
                    data-tippy-content="Copy"
                  >
                   {pid}
                  </span>
                </div>
                <div className="mb-2 flex items-center">
                  <span className="dark:text-jacarta-300 mr-2 min-w-[9rem] font-bold">
                    Token Standard:
                  </span>
                  <span className="text-jacarta-700 dark:text-white">
                  ERC-1155
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="dark:text-jacarta-300 mr-2 min-w-[9rem] font-bold">
                    Blockchain:
                  </span>
                  <span className="text-jacarta-700 dark:text-white">
                    Polygon
                  </span>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <Price_history id={pid} classes="dark:bg-black dark:border-accent-dark/50 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none rounded-tr-none border bg-white p-6" />
          </TabPanel>
          <TabPanel>
            <Properties properties={properties} />
          </TabPanel>
         
        </Tabs>
      </div>
    </>
  );
};

export default ItemsTabs;
