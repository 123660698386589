const newseLatterData = [
  {
    id: "1",
    icon: {
      parentBg: "#beaaf7",
      childBg: "rgb(131 88 255) ",
      svg: "icon-wallet",
    },

    title: "Set up your wallet",
    text: "Once you've set up your wallet of choice, connect it to ARTNXS by clicking the NFT Marketplacein the topright corner.",
  },
  {
    id: "2",
    icon: {
      parentBg: "#c4f2e3",
      childBg: "rgb(16 185 129)",
      svg: "icon-wallet",
    },

    title: "Create Your Collection",
    text: "Click Create and set up your collection. Add social links, a description, profile & banner images, and set a secondary sales fee.",
  },
  {
    id: "3",
    icon: {
      parentBg: "#cddffb",
      childBg: "rgb(66 138 248)",
      svg: "icon-gallery",
    },
    title: "Add Your NFTs",
    text: "Upload your work (image, video, audio, or 3D art), add a title and description, and customize your NFTs with properties, stats.",
  },
  {
    id: "4",
    icon: { parentBg: "#ffd0d0", childBg: "rgb(239 68 68)", svg: "icon-list" },
    title: "List Them For Sale",
    text: "Choose between auctions, fixed-price listings, and declining-price listings. You choose how you want to sell your NFTs!",
  },
];

export { newseLatterData };
