// import '/images/item_1.jpg' from '/images/item_1.jpg';

const bidsData = [
  {
    id: 1,
    image: "/images/nft/art.jpeg",
    title: "ETH Me Outside",
    bid_number: 1.55,
    eth_number: 1.3,
    react_number: 159,
  },
  {
    id: 2,
    image: "/images/nft/art.jpeg",
    title: "ETH Me Outside",
    bid_number: 1.55,
    eth_number: 1.3,
    react_number: 159,
  },
  {
    id: 3,
    image: "/images/nft/art.jpeg",
    title: "ETH Me Outside",
    bid_number: 1.55,
    eth_number: 1.3,
    react_number: 159,
  },
  {
    id: 4,
    image: "/images/nft/art.jpeg",
    title: "ETH Me Outside",
    bid_number: 1.55,
    eth_number: 1.3,
    react_number: 159,
  },
  {
    id: 5,
    image: "/images/nft/art.jpeg",
    title: "ETH Me Outside",
    bid_number: 1.55,
    eth_number: 1.3,
    react_number: 159,
  },
  {
    id: 6,
    image: "/images/nft/art.jpeg",
    title: "ETH Me Outside",
    bid_number: 1.55,
    eth_number: 1.3,
    react_number: 159,
  },
];

export { bidsData };
