import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { useNFTPriceChartQuery } from '../../redux/actions/nft';
import { Spin } from 'antd';
import { ERROR } from '../../utils/errors';
import { useRouter } from 'next/router';
import { useTheme } from "next-themes";

const Price_history = ({ classes }) => {
	const { theme } = useTheme();
	const {query} = useRouter();
	const [months, setMonths] = useState([])
	const {data, isFetching, isError, error} = useNFTPriceChartQuery({
		nft_id: query?.item
	})
	useEffect(() => {
      if(isError) {
		ERROR(error?.data?.error ? error?.data?.error : 'Something went wrong')
	  }
	  if(!isError) {
        if(data?.status) {
			if(data?.data?.dateTime?.length > 0) {
				const storeMonths = [];
				data?.data?.dateTime?.forEach(month => {
					// const date = new Date(month);
					// const monthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
					storeMonths.push(month.split('T')[0])
				})
				setMonths(storeMonths);
			}
		}
	  }
	}, [isError, data])
	console.log(data)
	return (
		<>
		<div className="relative mb-24 w-full">
			{/* <!-- Price History --> */}
			<div className="tab-pane fade">
				<div className={classes}>

					{/* <!-- Chart --> */}
					<div className="chart-container relative h-80 w-full">
						<Bar
							data={{
								labels: months,
								datasets: [
									{
										type: 'line',
										label: 'Avg. price',
										backgroundColor: '#10B981',
										borderColor: '#10B981',
										data: data?.data?.prices,
									},
								],
							}}
							options={{
								maintainAspectRatio: false,
								responsive: true,
								interaction: {
									intersect: false,
									mode: 'index',
								},
								scales: {
									x: {
										grid: {
											display: false,
										},
									},
									y: {
										ticks: {
											stepSize: 50,
										},
									},
								},
								plugins: {
									legend: { display: false },
									decimation: {
										enabled: true,
									},
									tooltip: {
										usePointStyle: true,
										position: 'nearest',
										backgroundColor: theme === 'dark' ? 'black' : '#131740',
										titleAlign: 'center',
										bodyAlign: 'center',
										footerAlign: 'center',
										borderColor: '#7444FF',
										borderWidth: theme === 'dark' && 1,
										padding: 12,
										displayColors: false,
										yAlign: 'bottom',
									},
								},
								animation: false,
							}}
						/>
					</div>
				</div>
			</div>
		</div>
		</>
	);
};

export default Price_history;
