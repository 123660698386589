import React from "react";
import { items_activity_data } from "../../data/items_tabs_data";
import Link from "next/link";
import Error from "../errors/Error";
import moment from "moment/moment";
import { config } from "../metamask/config";
import useTranslation from "next-translate/useTranslation";

const Activity_tab = ({activity}) => {
  const {t,lang} = useTranslation('nft')
  console.log('activity => ', activity)
  if(activity?.length === 0) {
    return <Error msg='There is no activity' />
  }
  return (
    <>
    
      {/* <!-- Activity --> */}
      <div
        className="tab-pane fade"
        id="activity"
        role="tabpanel"
        aria-labelledby="activity-tab"
      >
        <div
          role="table"
          className="scrollbar-custom dark:bg-black dark:border-accent-dark/50 border-jacarta-100 max-h-72 w-full overflow-y-auto rounded-lg rounded-tl-none rounded-tr-none border bg-white text-sm dark:text-white"
        >
          <div
            className="dark:bg-black bg-light-base sticky top-0 flex font-bold"
            role="row"
          >
            <div className="w-[17%] py-2 px-4" role="columnheader">
              <span className="text-black dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                TrxType
              </span>
            </div>
            <div className="w-[17%] py-2 px-4" role="columnheader">
              <span className="text-black dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                {t('price')}
              </span>
            </div>
            <div className="w-[22%] py-2 px-4" role="columnheader">
              <span className="text-black dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                {t('from')}
              </span>
            </div>
            <div className="w-[22%] py-2 px-4" role="columnheader">
              <span className="text-black dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                {t('to')}
              </span>
            </div>
            <div className="w-[22%] py-2 px-4" role="columnheader">
              <span className="text-black dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                {t('date')}
              </span>
            </div>
          </div>
          {activity.map((item) => {
            const { id, trxType,price, qty, fromAddress,toAddress,datetime, hash } = item;
            console.log(item)
            return (
              <div className="flex" role="row" key={id}>
                <div
                  className="dark:border-accent-dark border-jacarta-100 flex w-[17%] items-center font-bold border-t py-4 px-4"
                  role="cell"
                >
              
                  {trxType}
                </div>
                <div
                  className="dark:border-accent-dark border-jacarta-100 flex w-[17%] items-center whitespace-nowrap border-t py-4 px-4"
                  role="cell"
                >
                  <span className="-ml-1" data-tippy-content="ETH">
                    <svg className="icon mr-1 h-4 w-4">
                      <use xlinkHref="icons.svg#icon-ETH"></use>
                    </svg>
                  </span>
                  <span className="text-green text-sm font-medium tracking-tight">
                    {price} MATIC
                  </span>
                </div>
                <div
                  className="dark:border-accent-dark border-jacarta-100 flex w-[22%] items-center border-t py-4 px-4" 
                  role="cell"
                >
                  <a target="_blank" href={`${config.BASEURL_BLOCKCHAIN}/address/${fromAddress}`} className="text-accent dark:text-white">
                    {fromAddress?.slice(0,6)}...
                    {fromAddress?.slice(14,20)}
                  </a>
                </div>
                <div
                  className="dark:border-accent-dark border-jacarta-100 flex w-[22%] items-center border-t py-4 px-4"
                  role="cell"
                >
                  <Link href={`${config.BASEURL_BLOCKCHAIN}/address/${toAddress}`} className="text-accent dark:text-white">
                    {toAddress?.slice(0,6)}...
                    {toAddress?.slice(14,20)}
                  </Link>
                </div>
                <div
                  className="dark:border-accent-dark border-jacarta-100 flex w-[22%] items-center border-t py-4 px-4"
                  role="cell"
                >
                  <Link
                    href={`${config.BASEURL_BLOCKCHAIN}/tx/${hash}`}
                    className="text-accent flex flex-wrap items-center"
                    target="_blank"
                    rel="nofollow noopener"
                    title="Opens in a new window"
                    data-tippy-content="March 13 2022, 2:32 pm"
                  >
                    <span className="mr-1 dark:text-white">{moment(datetime).format('ll')}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="h-4 w-4 fill-current dark:text-accent-dark"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 6v2H5v11h11v-5h2v6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h6zm11-3v8h-2V6.413l-7.793 7.794-1.414-1.414L17.585 5H13V3h8z" />
                    </svg>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Activity_tab;
