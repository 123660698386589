/* eslint-disable @next/next/no-img-element */
import React from "react";
import { trendingCategoryData } from "../../data/categories_data";
import { HeadLine } from "../component";
import Trending_categories_items from "./trending_categories_items";
import useTranslation from "next-translate/useTranslation";

const Tranding_category = () => {
  const {t, lang} = useTranslation('common')
  return (
    <section className="py-24">
      <div className="container">
        <HeadLine
          text={t('trending')}
          classes="font-display text-jacarta-700 mb-8 text-center text-3xl dark:text-white"
        />

        {/* trending categories */}
        <Trending_categories_items />
      </div>
    </section>
  );
};

export default Tranding_category;
